import * as React from "react"
import { useContext } from "react"
import Layout from "../social-supermarket/components/Layout"
import { LoginContext } from "@social-supermarket/social-supermarket-components"
import SEO from "../social-supermarket/components/Seo"
import GiftingPage from "../social-supermarket/pages/gifting/GiftingPage"

const Gift = () => {
  const loginContext = useContext(LoginContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Gifting"} description={""} />
      <GiftingPage />
    </Layout>
  ) : (
    <Layout />
  )
}

export default Gift
